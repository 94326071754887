import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Home = React.lazy(() => import('./views/dashboard/Home'))

// Cursos
const Cursos = React.lazy(() => import('./views/cursos/list'))
const CursosAdd = React.lazy(() => import('./views/cursos/add'))
const CursosEdit = React.lazy(() => import('./views/cursos/edit'))

// Aulas
const Aulas = React.lazy(() => import('./views/aulas/list'))
const AulasAdd = React.lazy(() => import('./views/aulas/add'))
const AulasEdit = React.lazy(() => import('./views/aulas/edit'))

// Instituições
const Instituicoes = React.lazy(() => import('./views/instituicoes/list'))
const InstituicoesAdd = React.lazy(() => import('./views/instituicoes/add'))
const InstituicoesEdit = React.lazy(() => import('./views/instituicoes/edit'))

// Banco de Dados
const Banco = React.lazy(() => import('./views/banco_de_dados/list'))
const BancoAdd = React.lazy(() => import('./views/banco_de_dados/add'))
const BancoEdit = React.lazy(() => import('./views/banco_de_dados/edit'))

// Servidores
const Servidores = React.lazy(() => import('./views/servidores/list'))
const ServidoresAdd = React.lazy(() => import('./views/servidores/add'))
const ServidoresEdit = React.lazy(() => import('./views/servidores/edit'))

// Unidade de Ensino
const UnidadeEnsino = React.lazy(() => import('./views/unidade_ensino/list'))
const UnidadeEnsinoAdd = React.lazy(() => import('./views/unidade_ensino/add'))
const UnidadeEnsinoEdit = React.lazy(() => import('./views/unidade_ensino/edit'))

// Parametros
const Parametros = React.lazy(() => import('./views/parametros'))

// Banco de Questões
const BancoQuestoes = React.lazy(() => import('./views/banco_questoes/list'))
const BancoQuestoesAdd = React.lazy(() => import('./views/banco_questoes/add'))
const BancoQuestoesEdit = React.lazy(() => import('./views/banco_questoes/edit'))

// Suporte
const Suporte = React.lazy(() => import('./views/suporte'))

const AdministradoresList = React.lazy(() => import('./views/administradores/AdministradoresList'))
const AdministradoresAdd = React.lazy(() => import('./views/administradores/add'))
const AdministradoresEdit = React.lazy(() => import('./views/administradores/edit'))

const AdministradoresTiposList = React.lazy(() =>
  import('./views/administradores_tipos/AdministradoresTiposList'),
)
const AdministradoresTiposAdd = React.lazy(() => import('./views/administradores_tipos/add'))
const AdministradoresTiposEdit = React.lazy(() => import('./views/administradores_tipos/edit'))

const Colors = React.lazy(() => import('./views/theme/colors/Colors'))
const Typography = React.lazy(() => import('./views/theme/typography/Typography'))

// Base
const Accordion = React.lazy(() => import('./views/base/accordion/Accordion'))
const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'))
const Cards = React.lazy(() => import('./views/base/cards/Cards'))
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'))
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'))
const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'))
const Navs = React.lazy(() => import('./views/base/navs/Navs'))
const Paginations = React.lazy(() => import('./views/base/paginations/Paginations'))
const Placeholders = React.lazy(() => import('./views/base/placeholders/Placeholders'))
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'))
const Progress = React.lazy(() => import('./views/base/progress/Progress'))
const Spinners = React.lazy(() => import('./views/base/spinners/Spinners'))
const Tables = React.lazy(() => import('./views/base/tables/Tables'))
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'))

// Buttons
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'))
const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'))
const Dropdowns = React.lazy(() => import('./views/buttons/dropdowns/Dropdowns'))

//Forms
const ChecksRadios = React.lazy(() => import('./views/forms/checks-radios/ChecksRadios'))
const FloatingLabels = React.lazy(() => import('./views/forms/floating-labels/FloatingLabels'))
const FormControl = React.lazy(() => import('./views/forms/form-control/FormControl'))
const InputGroup = React.lazy(() => import('./views/forms/input-group/InputGroup'))
const Layout = React.lazy(() => import('./views/forms/layout/Layout'))
const Range = React.lazy(() => import('./views/forms/range/Range'))
const Select = React.lazy(() => import('./views/forms/select/Select'))
const Validation = React.lazy(() => import('./views/forms/validation/Validation'))

const Charts = React.lazy(() => import('./views/charts/Charts'))

// Icons
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'))
const Flags = React.lazy(() => import('./views/icons/flags/Flags'))
const Brands = React.lazy(() => import('./views/icons/brands/Brands'))

// Modulos
const Modulos = React.lazy(() => import('./views/modulos/list'))
const ModulosAdd = React.lazy(() => import('./views/modulos/add'))
const ModulosEdit = React.lazy(() => import('./views/modulos/edit'))

// Notifications
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'))
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'))
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'))
const Toasts = React.lazy(() => import('./views/notifications/toasts/Toasts'))
const Page403 = React.lazy(() => import('./views/pages/page403/Page403'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Aguarde = React.lazy(() => import('./views/pages/Aguarde'))

const Widgets = React.lazy(() => import('./views/widgets/Widgets'))

const routes = [
  { path: '/', exact: true, name: 'Home', component: Home },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  {
    path: '/administradores',
    exact: true,
    name: 'Lista de Administradores',
    component: AdministradoresList,
  },
  {
    path: '/administradores/add',
    name: 'Adicionar Administradores',
    component: AdministradoresAdd,
  },
  {
    path: '/administradores/edit/:id',
    exact: true,
    name: 'Editar Administradores',
    component: AdministradoresEdit,
  },
  {
    path: '/administradores_tipos',
    exact: true,
    name: 'Tipos de Administradores',
    component: AdministradoresTiposList,
  },
  {
    path: '/administradores_tipos/add',
    name: 'Adicionar Tipos de Administradores',
    component: AdministradoresTiposAdd,
  },
  {
    path: '/administradores_tipos/edit/:id',
    exact: true,
    name: 'Editar Tipos de Administradores',
    component: AdministradoresTiposEdit,
  },
  { path: '/banco_questoes', name: 'Banco de Questões', component: BancoQuestoes, exact: true },
  {
    path: '/banco_questoes/add',
    name: 'Adicionar no Banco de Questões',
    component: BancoQuestoesAdd,
  },
  {
    path: '/banco_questoes/edit/:id',
    name: 'Editar no Banco de Questões',
    component: BancoQuestoesEdit,
  },

  { path: '/cursos', name: 'Cursos', component: Cursos, exact: true },
  { path: '/cursos/add', name: 'Adicionar Curso', component: CursosAdd },
  { path: '/cursos/edit/:id', name: 'Editar Curso', component: CursosEdit, exact: true },
  { path: '/aulas', name: 'Aulas', component: Aulas, exact: true },
  { path: '/aulas/add', name: 'Adicionar Aula', component: AulasAdd },
  { path: '/aulas/edit/:id', name: 'Editar Aula', component: AulasEdit, exact: true },
  // { path: '/cursos/edit/:id', name: 'Adicionar Curso', component: CursosEdit, exact: true },
  { path: '/instituicoes', name: 'Instituicoes', component: Instituicoes, exact: true },
  { path: '/instituicoes/add', name: 'Adicionar Instituicoes', component: InstituicoesAdd },
  { path: '/instituicoes/edit/:id', name: 'Editar Instituicoes', component: InstituicoesEdit },
  { path: '/banco_de_dados', name: 'Banco de Dados', component: Banco, exact: true },
  { path: '/banco_de_dados/add', name: 'Adicionar Banco de Dados', component: BancoAdd },
  { path: '/banco_de_dados/edit/:id', name: 'Editar Banco de Dados', component: BancoEdit },
  { path: '/servidores', name: 'Servidores', component: Servidores, exact: true },
  { path: '/servidores/add', name: 'Adicionar Servidores', component: ServidoresAdd },
  { path: '/servidores/edit/:id', name: 'Editar Servidores', component: ServidoresEdit },
  { path: '/unidade_ensino', name: 'Unidade Ensino', component: UnidadeEnsino, exact: true },
  { path: '/unidade_ensino/add', name: 'Adicionar Unidade de Ensino', component: UnidadeEnsinoAdd },
  { path: '/unidade_ensino/edit', name: 'Editar Unidade de Ensino', component: UnidadeEnsinoEdit },
  { path: '/parametros', name: 'Parametros', component: Parametros, exact: true },
  { path: '/suporte', name: 'Suporte', component: Suporte, exact: true },
  { path: '/theme', name: 'Theme', component: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', name: 'Base', component: Cards, exact: true },
  { path: '/base/accordion', name: 'Accordion', component: Accordion },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/placeholders', name: 'Placeholders', component: Placeholders },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress', name: 'Progress', component: Progress },
  { path: '/base/spinners', name: 'Spinners', component: Spinners },
  { path: '/base/tables', name: 'Tables', component: Tables },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/dropdowns', name: 'Dropdowns', component: Dropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/charts', name: 'Charts', component: Charts },
  { path: '/forms', name: 'Forms', component: FormControl, exact: true },
  { path: '/forms/form-control', name: 'Form Control', component: FormControl },
  { path: '/forms/select', name: 'Select', component: Select },
  { path: '/forms/checks-radios', name: 'Checks & Radios', component: ChecksRadios },
  { path: '/forms/range', name: 'Range', component: Range },
  { path: '/forms/input-group', name: 'Input Group', component: InputGroup },
  { path: '/forms/floating-labels', name: 'Floating Labels', component: FloatingLabels },
  { path: '/forms/layout', name: 'Layout', component: Layout },
  { path: '/forms/validation', name: 'Validation', component: Validation },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/brands', name: 'Brands', component: Brands },

  { path: '/modulos', name: 'Modulos', component: Modulos, exact: true },
  { path: '/modulos/add', name: 'Adicionar Modulo', component: ModulosAdd },
  { path: '/modulos/edit/:id', name: 'Adicionar Modulo', component: ModulosEdit, exact: true },
  { path: '/modulos/:id_modulo/aulas', name: 'Aulas', component: Aulas, exact: true },
  { path: '/modulos/:id_modulo/aulas/add', name: 'Adicionar Aula', component: AulasAdd },
  {
    path: '/modulos/:id_modulo/aulas/edit/:id',
    name: 'Editar Aula',
    component: AulasEdit,
    exact: true,
  },

  { path: '/notifications', name: 'Notifications', component: Alerts, exact: true },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  { path: '/notifications/badges', name: 'Badges', component: Badges },
  { path: '/notifications/modals', name: 'Modals', component: Modals },
  { path: '/notifications/toasts', name: 'Toasts', component: Toasts },
  { path: '/widgets', name: 'Widgets', component: Widgets },
  { path: '/403', name: 'Widgets', component: Page403 },
  { path: '/404', name: 'Widgets', component: Page404 },
  { path: '/aguarde', name: 'Widgets', component: Aguarde },
]

export default routes
