import * as Actions from '../redux_actions/userActions'
const initialState = {
  userData: {
    name: null,
    slug: null,
  },
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SIGNIN:
      return Object.assign({}, state, {
        userData: {
          name: action.userData.name,
          slug: action.userData.slug,
        },
      })
    case Actions.SIGNOUT:
      return Object.assign({}, state, {
        userData: {
          name: null,
          slug: null,
        },
      })
    case Actions.SETUSERDATA:
      // console.log(action)
      return Object.assign({}, state, {
        userData: {
          name: action.userData.name,
          slug: action.userData.slug,
        },
      })
    default:
      return state
  }
}
