import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
import MySwitch from './MySwitch'

export default function App() {
  return (
    <BrowserRouter>
      <MySwitch></MySwitch>
    </BrowserRouter>
  )
}
