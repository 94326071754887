import React, { useEffect } from 'react'
// import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons'

import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
// import { logo } from '/app/src/assets/brand/logo'
import * as SidebarActions from '../redux_actions/sidebarActions'
import { sidebarReducer } from '../redux_reducers/sidebarReducer'
import { useAjaxBffErrorHandler } from '../service/BffHandler'

const AppHeader = () => {
  const dispatch = useDispatch()
  const [ajaxBffReducer, hasError] = useAjaxBffErrorHandler()
  const sidebarShowReducer = useSelector((state) => state.sidebarReducer)

  useEffect(() => {
    if (hasError()) {
      console.log('Erro no efeito do cabeçalho. Tratar na interface.')
    }
    // eslint-disable-next-line
  }, [ajaxBffReducer])

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          // onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
          onClick={() =>
            dispatch(
              SidebarActions.sidebarToogle(
                !sidebarShowReducer.sidebarShow,
                sidebarReducer.unfoldable,
              ),
            )
          }
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          {/* <CIcon icon={logo} height={48} alt="Logo" /> */}
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          {/* <CNavItem>
            <CNavLink to="/dashboard" component={NavLink} activeClassName="active">
              Dashboard
            </CNavLink>
          </CNavItem> */}
          {/* <CNavItem>
            <CNavLink to="/administradores" component={NavLink}>
              Administradores
            </CNavLink>
          </CNavItem> */}
          {/* <CNavItem>
            <CNavLink to="/relatorios" component={NavLink}>
              Relatórios
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink to="/configuracoes" component={NavLink}>
              Configurações
            </CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilBell} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilList} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
