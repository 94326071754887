import React, { useEffect, useState } from 'react'
import { CCard, CButton, CCardBody, CCardGroup, CCol, CContainer, CForm, CRow } from '@coreui/react'
import { useAjaxBffHandler } from '../../../service/BffHandler'
// import { logout } from '/app/src/service/AuthService'

const Logout = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [logoutSuccess, setLogoutSuccess] = useState(null)

  const { ajaxCall } = useAjaxBffHandler()

  useEffect(() => {
    const fetchAsyncLogout = async () => {
      const fetchResult = await ajaxCall('GET', 'logout', false, false)
      setLogoutSuccess(fetchResult.status)
      setIsFirstLoading(false)
    }
    if (isFirstLoading) {
      fetchAsyncLogout()
    }
  }, [isFirstLoading, ajaxCall])

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  {logoutSuccess === true ? (
                    <CForm>
                      <h1>Logout realizado com sucesso.</h1>
                      <p>Obrigado e volte sempre.</p>
                      <CButton href="/login" color="link">
                        Voltar para Login.
                      </CButton>
                    </CForm>
                  ) : (
                    <CForm>
                      <h1>Erro ao fazer logout.</h1>
                      <p>
                        Esta situação está ocorrendo devido ao estado imaturo do sistema. Em breve
                        será corrigida.
                      </p>
                    </CForm>
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Logout
