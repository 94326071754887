import * as Actions from '../redux_actions/alertActions'
const initialState = {
  color: 'primary',
  dismissible: true,
  visible: false,
  message: '',
}

export const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CHANGE_ALERT:
      return Object.assign({}, state, {
        color: action.color,
        dismissible: action.dismissible,
        visible: action.visible,
        message: action.message,
      })
    default:
      return state
  }
}
