import React, { Suspense } from 'react'

import { Switch, Route } from 'react-router-dom'
import { CContainer } from '@coreui/react'
import { ContentRouteMiddleware } from '../middleware/ContentRouteMiddleware'

// routes config
import routes from '../routes'
import ClockSpinner from '../views/pages/ClockSpinner'
import { useBffReducer } from '../service/BffHandler'

const AppContent = () => {
  const { bffReducer } = useBffReducer()
  if (bffReducer.bffState === 'initial') return <ClockSpinner />

  return (
    <CContainer lg>
      <Suspense fallback={<ClockSpinner height="80vh" />}>
        <Switch>
          {routes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => (
                    <ContentRouteMiddleware>
                      <route.component {...props} />
                    </ContentRouteMiddleware>
                  )}
                />
              )
            )
          })}
          {/* <Redirect from="/" to="/dashboard" /> */}
        </Switch>
      </Suspense>
    </CContainer>
  )
}

export default React.memo(AppContent)
