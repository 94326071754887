import * as Actions from '../redux_actions/bffActions'
const initialState = {
  has_error: false,
  message: 'Initial State',
  redirect_url: false,
  payload: false,
  // status: null,
  // access_permission: null,
  location_pathname: '',
  httpCode: null,
  bffState: 'initial',
}

export const bffReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CHANGE_VALUE:
      return Object.assign({}, state, {
        has_error: false,
        message: action.message,
        redirect_url: action.redirect_url,
        payload: action.payload,
        // status: action.status,
        // access_permission: action.access_permission,
        location_pathname: action.location_pathname,
        httpCode: action.httpCode,
        bffState: 'changed',
      })

    case Actions.RESET_BFF_REDUCER:
      return Object.assign({}, {}, initialState)

    case Actions.HAS_ERROR:
      return Object.assign({}, state, {
        has_error: true,
        message: action.message,
        redirect_url: action.redirect_url,
        payload: action.payload,
        // status: action.status,
        // access_permission: action.access_permission,
        location_pathname: action.location_pathname,
        httpCode: action.httpCode,
        bffState: 'error',
      })
    default:
      return state
  }
}
