import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
// import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

// import { logoNegative } from '/app/src/assets/brand/logo-negative'
// import { sygnet } from '/app/src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// import VisualLogo from '/app/src/assets/images/logo/visual_logo.jpg'

import * as SidebarActions from '../redux_actions/sidebarActions'

// sidebar nav config
import navigation from '../_nav'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const sidebarReducer = useSelector((state) => state.sidebarReducer)
  // const [unfoldable, setUnfoldable] = useState(true)
  // const [sidebarShow, setsidebarShow] = useState(true)

  // console.log('novo sidebar')

  useEffect(() => {
    // setUnfoldable(sidebarReducer.unfoldable)
    // setsidebarShow(sidebarReducer.sidebarShow)
  }, [sidebarReducer])

  return (
    <CSidebar
      position="fixed"
      unfoldable={sidebarReducer.unfoldable}
      visible={sidebarReducer.sidebarShow}
      onVisibleChange={(visible) => {
        dispatch(SidebarActions.sidebarToogle(visible, sidebarReducer.unfoldable))
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} /> */}
        {/* <img alt="Visual Logo" className="visual-logo" src={VisualLogo} /> */}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() =>
          dispatch(
            SidebarActions.sidebarToogle(sidebarReducer.sidebarShow, !sidebarReducer.unfoldable),
          )
        }
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
