import * as Actions from '../redux_actions/formGenericActions'

/**
 *  dataElement: {
 *    type: '',   
 *
      // initial data
      value: '',    
      isFetchingRemoteInitialData: true || false,
      
      // local validation
      localValidationOnBlur: true || false,
      localValidationOnChange: true || false,
      
      // remote validation
      isFetchingRemoteValidation: true || false,
      remoteValidationOnBlur: true || false,
      remoteValidationOnChange: true || false,
      
      // validation status
      isValidated: true || false,
      isValid: true || false,
 *  }
 *  */
const initialState = {
  data: {},
  submitData: {},
  isValidated: false,
  isValid: false,
  onSubmit: false,
}

export const formGenericReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CHANGE_DATA:
      return Object.assign({}, state, {
        data: action.data,
      })
    case Actions.CHANGE_SUBMIT_DATA:
      return Object.assign({}, state, {
        submitData: action.submitData,
      })
    case Actions.CHANGE_ON_SUBMIT:
      return Object.assign({}, state, {
        onSubmit: action.onSubmit,
      })
    case Actions.CHANGE_FORM_REDUCER:
      return Object.assign({}, state, action.data)
    case Actions.RESET_FORM_REDUCER:
      console.log('resetando o form', state)
      return Object.assign({}, {}, initialState)
    default:
      return state
  }
}
