import React from 'react'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CForm, CRow } from '@coreui/react'

const Aguarde = () => {
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Erro de Comunicação</h1>
                    <p>Não foi possível estabelecer uma conexão com o servidor.</p>
                    <p>
                      Tente novamente e em caso de persistência do erro, entrar em contato com o
                      suporte.
                    </p>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Aguarde
