export const CHANGE_DATA = 'CHANGE_DATA'
export const CHANGE_SUBMIT_DATA = 'CHANGE_SUBMIT_DATA'
export const CHANGE_DATA_LOADING = 'CHANGE_DATA_LOADING'
export const CHANGE_DATA_VALIDATION = 'CHANGE_DATA_VALIDATION'
export const CHANGE_ON_SUBMIT = 'CHANGE_ON_SUBMIT'
export const CHANGE_FORM_REDUCER = 'CHANGE_FORM_REDUCER'
export const RESET_FORM_REDUCER = 'RESET_FORM_REDUCER'

export const changeData = (form_values) => {
  return {
    type: CHANGE_DATA,
    data: form_values,
  }
}

export const changeSubmitData = (form_values) => {
  return {
    type: CHANGE_SUBMIT_DATA,
    submitData: form_values,
  }
}

export const changeOnSubmit = (data) => {
  return {
    type: CHANGE_ON_SUBMIT,
    onSubmit: data,
  }
}

export const changeFormReducer = (data) => {
  return {
    type: CHANGE_FORM_REDUCER,
    data: data,
  }
}

export const resetFormReducer = () => {
  return {
    type: RESET_FORM_REDUCER,
  }
}
