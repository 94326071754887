export const CHANGE_ALERT = 'CHANGE_ALERT'

export const changeAlert = (alert) => {
  return {
    type: CHANGE_ALERT,
    color: alert.color,
    dismissible: alert.dismissible,
    visible: alert.visible,
    message: alert.message,
  }
}
