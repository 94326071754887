import * as Actions from '../redux_actions/ajaxBffActions'
const initialState = {
  has_error: false,
  message: '',
  redirect_url: false,
  payload: [],
  status: false,
  access_permission: null,
  httpCode: null,
}

export const ajaxBffReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CHANGE_VALUE:
      return Object.assign({}, state, {
        has_error: false,
        message: action.message,
        redirect_url: action.redirect_url,
        payload: action.payload,
        status: action.status,
        access_permission: action.access_permission,
        httpCode: action.httpCode,
      })
    case Actions.HAS_ERROR:
      return Object.assign({}, state, {
        has_error: true,
        message: action.message,
        redirect_url: action.redirect_url,
        payload: action.payload,
        status: action.status,
        access_permission: action.access_permission,
        httpCode: action.httpCode,
      })
    default:
      return state
  }
}
