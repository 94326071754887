import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilCursor,
  cilNotes,
  cilPuzzle,
  cilSpeedometer,
  // cilStar,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Meu Principal',
  },
  {
    component: CNavGroup,
    name: 'Instituições',
    to: '/instituicoes',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Lista Instituições',
        to: '/instituicoes',
      },
      /*{
        component: CNavItem,
        name: 'Lista Unidade de Ensino',
        to: '/unidade_ensino',
      },*/
    ],
  },
  {
    component: CNavGroup,
    name: 'Cursos',
    to: '/cursos',
    icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
    items: [
      // {
      //   component: CNavItem,
      //   name: 'Aulas',
      //   to: '/aulas',
      // },
      {
        component: CNavItem,
        name: 'Cursos',
        to: '/cursos',
      },
      {
        component: CNavItem,
        name: 'Modulos',
        to: '/modulos',
      },
      {
        component: CNavItem,
        name: 'Aulas',
        to: '/aulas',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Financeiro',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Dashboard Financeiro',
        to: '/financeiro',
      },
    ],
  },
]

export default _nav
