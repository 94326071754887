import { useSelector, useDispatch } from 'react-redux'

import { setUserData } from '../../redux_actions/userActions'
import { useAjaxBffHandler } from '../BffHandler/BffHandler'

export const useUserHandler = () => {
  const userReducer = useSelector((state) => state.userReducer)
  const dispatch = useDispatch()
  const { ajaxCall } = useAjaxBffHandler()

  const fetchUserData = () => {
    ajaxCall('GET', 'user/data').then((response) => {
      // console.log('resposta', response)
      if (response.payload !== []) {
        dispatch(setUserData(response.payload?.name, response.payload?.slug))
      }
    })
  }

  return {
    userReducer: userReducer,
    fetchUserData: fetchUserData,
  }
}
