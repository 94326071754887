import * as Actions from '../redux_actions/sidebarActions'

const initialState = {
  sidebarShow: true,
  unfoldable: false,
}

export const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SIDEBAR_TOOGLE:
      return Object.assign({}, state, {
        sidebarShow: action.sidebarShow,
        unfoldable: action.unfoldable,
      })
    default:
      return state
  }
}

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }
