import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import * as bffActions from '../../redux_actions/bffActions'
import { useState } from 'react'
// import { useAlertHandler } from './AlertHandler/index.js'
import { resetFormReducer } from '../../redux_actions/formGenericActions.js'
import { useToastHandler } from '../ToastHandler/index.js'

const LOCALHOST_URL = process.env.REACT_APP_BFF_URL
// const LOCALHOST_URL = 'https://bff.testeplataformavisual.com.br/'

const get_request = {
  method: 'GET',
  mode: 'cors',
  cache: 'no-store',
  credentials: 'include',
}

const post_request = {
  // headers: post_headers,
  headers: {
    'Content-Type': 'application/json',
  },
  method: 'POST',
  mode: 'cors',
  cache: 'no-store',
  credentials: 'include',
}

export const useAjaxBffHandler = () => {
  console.log('url', LOCALHOST_URL)
  const ajaxBffReducer = useSelector((state) => state.ajaxBffReducer)
  // const { showAlertReducer, isShowAlert } = useAlertHandler()
  const dispatch = useDispatch()
  const history = useHistory()
  const { showToast } = useToastHandler()

  const ajaxCall = (http_method, function_uri, body, query_params_obj) => {
    const method_headers = http_method === 'POST' ? post_request : get_request
    var query_string = []
    for (const p in query_params_obj)
      if (query_params_obj.hasOwnProperty(p)) {
        query_string.push(encodeURIComponent(p) + '=' + encodeURIComponent(query_params_obj[p]))
      }
    const query_text = query_string.join('&')
    let header_object = {
      ...method_headers,
    }
    if (body !== false && body !== null) {
      console.log('dentro do if', body)
      header_object.body = JSON.stringify(body)
    }
    console.log(body, header_object)
    return fetch(`${LOCALHOST_URL}/${function_uri}?${query_text}`, header_object).then(
      (response) => {
        return response
          .json()
          .then((response_json) => {
            response_json.httpCode = response.status

            if (response_json.show_alert) {
              const newToast = {
                autohide: true,
                color: response_json.type_alert ?? 'warning',
                delay: 10000,
                visible: true,
                dismissable: true,
                message: response_json.message,
              }
              showToast(newToast)
            }
            // console.log('response do ajaxbffhandler', response_json)
            if (response_json.redirect_url !== false) {
              dispatch(resetFormReducer())
              history.push(response_json.redirect_url)
            }
            return response_json
          })
          .catch((error) => console.log('Entrou no catch: ', error))
      },
    )
  }
  return { ajaxBffReducer: ajaxBffReducer, ajaxCall: ajaxCall }
}

export const useAjaxBffErrorHandler = () => {
  const ajaxBffReducer = useSelector((state) => state.ajaxBffReducer)

  const hasError = () => {
    if (ajaxBffReducer.has_error === true) {
      return true
    }
    return false
  }

  return [ajaxBffReducer, hasError]
}

/**
 *
 * @returns bffReducer - the reducer
 * @returns location - actual location
 * @returns isLoadingBffRequest - if has some fetch open - start as false, then goes to true, then returns false on finish.
 * @returns loadBffFinished - if have some loadbff Finished - start as false, then goes to true and stays.
 */
export const useOnRouteChangeBffHandler = () => {
  const bffReducer = useSelector((state) => state.bffReducer)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const [isLoading, setIsLoading] = useState(false)
  const [loadFinished, setLoadFinished] = useState(false)
  const { showToast } = useToastHandler()

  const bffCall = (
    http_method = false,
    function_uri = false,
    body = false,
    query_params_obj = false,
  ) => {
    setIsLoading(true)
    const method_headers = http_method === 'POST' ? post_request : get_request
    const url = function_uri !== false ? function_uri : location.pathname
    let query_string = []
    for (let p in query_params_obj)
      if (query_params_obj.hasOwnProperty(p)) {
        query_string.push(encodeURIComponent(p) + '=' + encodeURIComponent(query_params_obj[p]))
      }
    const query_text = query_string.join('&')
    let header_object = {
      ...method_headers,
    }
    if (body !== false) {
      header_object.body = body
    }
    return fetch(`${LOCALHOST_URL}/${url}?${query_text}`, header_object)
      .then((response) => {
        response.json().then((response_json) => {
          // if has someelse to extrat from location, must do it here
          response_json.location_pathname = url
          response_json.httpCode = response.status

          if (response_json.show_alert) {
            const newToast = {
              autohide: true,
              color: response_json.type_alert ?? 'warning',
              delay: 10000,
              visible: true,
              dismissable: true,
              message: response_json.message,
            }
            showToast(newToast)
          }
          dispatch(bffActions.changeValue(response_json))
          setIsLoading(false)
          setLoadFinished(true)

          return response_json
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const resetBffReducer = () => {
    dispatch(bffActions.resetBffReducer())
  }

  return {
    isLoadingBffRequest: isLoading,
    bffReducer: bffReducer,
    loadBffFinished: loadFinished,
    resetBffReducer,
    bffCall: bffCall,
    location: location,
    match: match,
    history: history,
  }
}

export const useBffReducer = () => {
  const bffReducer = useSelector((state) => state.bffReducer)

  return {
    bffReducer: bffReducer,
  }
}
