import * as Actions from '../redux_actions/toastActions'
// const toastDefault = {
//   color: 'info',
//   visible: false,
//   message: '',
//   autohide: true,
//   delay: 10000,
// }

// const initialState = {
//   toastList: [],
//   toastLength: 0,
// }

const initialState = []

// export const toastReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case Actions.CHANGE_TOAST:
//       return Object.assign({}, state, {
//         color: action.color,
//         visible: action.visible,
//         message: action.message,
//         autohide: action.autohide,
//         delay: action.delay,
//       })
//     case Actions.ADD_TOAST:
//       return Object.assign({}, state, {
//         color: action.color,
//         visible: action.visible,
//         message: action.message,
//         autohide: action.autohide,
//         delay: action.delay,
//       })

//     case Actions.DELETE_TOAST:
//       return Object.assign({}, {}, initialState)
//     default:
//       return state
//   }
// }

export const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.ADD_TOAST:
      console.log('add toast', action, state)
      // const newState = {
      //   toastList: [...state.toastList, action.toast],
      //   toastLength: state.toastLength + 1,
      // }

      // return Object.assign({}, state, newState)

      return [action.toast, ...state]

    case Actions.REMOVE_TOAST:
      // state.toastList.pop()
      console.log('action index', action)
      const newState = state.filter((toast, index, arr) => index !== action.index)
      console.log('remove newState', newState)
      return newState
    // return Object.assign({}, state, state.pop())

    // case Actions.DELETE_TOAST:
    //   return Object.assign({}, {}, initialState)
    default:
      return state
  }
}
