import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useOnRouteChangeBffHandler } from './service/BffHandler/BffHandler'

import ErroComunicacao from './views/pages/ErroComunicacao'

import DefaultLayout from './layout/DefaultLayout'
import Login from './views/pages/login/Login'
import Logout from './views/pages/logout/Logout'

import Page400 from './views/pages/page400/Page400'
import Page401 from './views/pages/page401/Page401'
import Page404 from './views/pages/page404/Page404'
import Page500 from './views/pages/page500/Page500'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function MySwitch() {
  // custom hook to aux with bff calls
  const { bffReducer, bffCall, resetBffReducer, location } = useOnRouteChangeBffHandler()
  // const { userReducer, fetchUserData } = useUserHandler()

  useEffect(() => {
    const fetchDataAsync = () => {
      resetBffReducer()
      bffCall('GET', location.pathname)
    }
    fetchDataAsync()
    // eslint-disable-next-line
  }, [location])

  const RedirectController = (route) => {
    if (bffReducer.httpCode >= 200 && bffReducer.httpCode <= 299) {
      return route
    }

    if (bffReducer.httpCode === 400) {
      if (bffReducer.redirect_url !== false) {
        return <Redirect to={bffReducer.redirect_url} />
      }
      return <Redirect to="/400" />
    }

    if (bffReducer.httpCode === 401) {
      if (bffReducer.redirect_url !== false) {
        return <Redirect to={bffReducer.redirect_url} />
      }
      if (bffReducer.redirect_url === '/') {
        return <Redirect to="/logout" />
      }
      return <Redirect to="/" />
    }

    if (bffReducer.httpCode === 406) {
      if (bffReducer.redirect_url !== false) {
        return <Redirect to={bffReducer.redirect_url} />
      }
      return <Redirect to="/406" />
    }

    if (bffReducer.access_permision === false) {
      if (bffReducer.redirect_url !== false) {
        return <Redirect to={bffReducer.redirect_url} />
      }
      return <Redirect to="/login" />
    }

    if (bffReducer.status === false) {
      return <ErroComunicacao />
    }

    if (bffReducer.location_pathname === 'dashboard') {
      return <Redirect to="/" />
    }

    return route
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Switch>
        <Route exact path="/carregando" name="Carregando">
          {bffReducer.location_pathname === 'dashboard' ? <Redirect to="/" /> : <></>}
        </Route>
        <Route exact path="/erro_comunicacao" name="ErroComunicacao">
          {bffReducer.isFetchError === false ? <ErroComunicacao /> : <></>}

          {bffReducer.location_pathname === 'dashboard' ? <Redirect to="/" /> : <></>}
        </Route>
        <Route exact path="/login" name="Login Page">
          {RedirectController(<Login />)}
        </Route>
        <Route exact path="/logout" name="Logout Page">
          {RedirectController(<Logout />)}
        </Route>
        <Route exact path="/400" name="Page 400">
          {RedirectController(<Page400 />)}
        </Route>
        <Route exact path="/401" name="Page 401">
          {RedirectController(<Page401 />)}
        </Route>
        <Route exact path="/404" name="Page 404">
          {RedirectController(<Page404 />)}
        </Route>
        <Route exact path="/500" name="Page 500">
          {RedirectController(<Page500 />)}
        </Route>

        <Route path="/" name="Home">
          {RedirectController(<DefaultLayout />)}
        </Route>
      </Switch>
    </>
  )
}
