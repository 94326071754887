// export const CHANGE_TOAST = 'CHANGE_TOAST'
export const ADD_TOAST = 'ADD_TOAST'
// export const DELETE_TOAST = 'DELETE_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'

// export const changeToast = (alert) => {
//   return {
//     type: CHANGE_TOAST,
//     color: alert.color ?? 'info',
//     dismissible: alert.dismissible ?? true,
//     visible: alert.visible ?? true,
//     message: alert.message ?? '',
//     autohide: alert.autohide ?? true,
//     delay: alert.delay ?? 10000,
//   }
// }

// export const deleteToast = () => {
//   return {
//     type: DELETE_TOAST,
//   }
// }

export const addToast = (toast) => {
  return {
    type: ADD_TOAST,
    toast: toast,
  }
}

export const removeToast = (index) => {
  return {
    type: REMOVE_TOAST,
    index: index,
  }
}
