import { useSelector, useDispatch } from 'react-redux'
import { addToast, removeToast } from '../../redux_actions/toastActions'
import { toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
export const useToastHandler = () => {
  const toastReducer = useSelector((state) => state.toastReducer)
  // console.log('toast reducer', toastReducer)
  const dispatch = useDispatch()
  const addToastReducer = (newToast) => {
    dispatch(addToast(newToast))
  }

  // const changeToastReducer = (newToast) => {
  //   dispatch(changeToast(newToast))
  // }

  // const showToastReducer = (color, message) => {
  //   dispatch(changeToast({ color: color, visible: true, message: message }))
  // }

  // const deleteToastReducer = () => {
  //   dispatch(deleteToast())
  // }

  const removeToastReducer = (index) => {
    dispatch(removeToast(index))
  }

  // const isShowToast = () => {
  //   return toastReducer.visible
  // }

  const showToast = (toastData) => {
    const toastOptions = {
      type: toastData.color ?? 'info',
      position: 'top-center',
      theme: 'colored',
    }
    toast(toastData.message ?? '', toastOptions)
  }

  return {
    toastReducer: toastReducer,
    addToastReducer: addToastReducer,
    // changeToastReducer: changeToastReducer,
    // showToastReducer: showToastReducer,
    removeToastReducer: removeToastReducer,
    showToast: showToast,
    // isShowToast: isShowToast,
    // deleteToastReducer: deleteToastReducer,
  }
}
