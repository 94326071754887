export const CHANGE_VALUE = 'CHANGE_VALUE'
export const HAS_ERROR = 'HAS_ERROR'

export const changeValue = (bff_return) => {
  console.log('Ao mudar o value do ajax bff: ', {
    type: CHANGE_VALUE,
    message: bff_return.message,
    redirect_url: bff_return.redirect_url,
    payload: bff_return.payload,
    status: bff_return.status,
    access_permission: bff_return.access_permission,
    httpCode: bff_return.httpCode,
  })
  return {
    type: CHANGE_VALUE,
    message: bff_return.message,
    redirect_url: bff_return.redirect_url,
    payload: bff_return.payload,
    status: bff_return.status,
    access_permission: bff_return.access_permission,
    httpCode: bff_return.httpCode,
  }
}

export const hasError = (bff_return) => {
  return {
    type: HAS_ERROR,
    message: bff_return.message,
    redirect_url: bff_return.redirect_url,
    payload: bff_return.payload,
    status: bff_return.status,
    access_permission: bff_return.access_permission,
    httpCode: bff_return.httpCode,
  }
}
