import React from 'react'
import { Redirect } from 'react-router-dom'
import { useBffReducer } from '../service/BffHandler/BffHandler'
import Aguarde from '../views/pages/Aguarde'

export const ContentRouteMiddleware = (props) => {
  // const match = useRouteMatch()
  // const location = useLocation()
  const { bffReducer, isLoadingBffRequest } = useBffReducer()

  // console.log('match', match, location)
  // console.log('bff reducer', bffReducer)

  // console.log('teste')
  if (isLoadingBffRequest) {
    return <Aguarde />
  }
  if (bffReducer.httpCode === 401) {
    if (bffReducer.redirect_url !== false) {
      return <Redirect to={bffReducer.redirect_url} />
    }
    return <Redirect to="/401" />
  }
  if (bffReducer.httpCode === 403) {
    if (bffReducer.redirect_url !== false) {
      return <Redirect to={bffReducer.redirect_url} />
    }
    return <Redirect to="/403" />
  }
  if (bffReducer.httpCode === 404) {
    if (bffReducer.redirect_url !== false) {
      return <Redirect to={bffReducer.redirect_url} />
    }
    return <Redirect to="/404" />
  }
  return props.children
}
