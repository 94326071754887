import React, { useEffect, useState } from 'react'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import logo from '../../../assets/images/logo/visual_logo.jpg'
import background01 from '../../../assets/images/background01.jpg'

import { useAjaxBffHandler } from '../../../service/BffHandler'

import BarLoader from 'react-spinners/BarLoader'

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [onLoading, setOnLoading] = useState('off')
  const { ajaxCall } = useAjaxBffHandler()
  const [loginAjaxResponse, setLoginAjaxResponse] = useState({ httpCode: null, message: '' })

  const handleLoginButton = () => {
    setOnLoading('onLoading')
    ajaxCall('POST', 'login/submit', { username: username, password: password }, false)
      .then((response) => {
        setOnLoading('onResult')
        // console.log('teste')
        // console.log(response)
        setLoginAjaxResponse(response)
      })
      .catch((response) => {
        console.log('capturou erro', response)
      })
  }

  useEffect(() => {
    // console.log('changed value, ', loginAjaxResponse)
  }, [loginAjaxResponse])

  const handleKeyDownEvent = (event) => {
    const form = event.target.form
    // console.log('form', form)
    const index = [...form].indexOf(event.target)
    // console.log('elemento atual', index)
    const next = form.elements[index + 1]
    // console.log('next element', next)
    // console.log('next element type', next.type)
    // form.elements[index + 1].focus()
    if (event.keyCode === 13) {
      if (next.type === 'button') {
        handleLoginButton()
      } else {
        form.elements[index + 1].focus()
      }
      // console.log('dentro do enter:', event)
    }
  }

  const getLoginInputs = () => {
    return (
      <>
        <CInputGroup className="mb-3">
          <CInputGroupText>
            <CIcon icon={cilUser} />
          </CInputGroupText>
          <CFormInput
            placeholder="Usuário"
            autoComplete="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={(event) => handleKeyDownEvent(event)}
          />
        </CInputGroup>
        <CInputGroup className="mb-4">
          <CInputGroupText>
            <CIcon icon={cilLockLocked} />
          </CInputGroupText>
          <CFormInput
            type="password"
            placeholder="Senha"
            autoComplete="current-password"
            value={password}
            onKeyDown={(event) => handleKeyDownEvent(event)}
            onChange={(e) => setPassword(e.target.value)}
          />
        </CInputGroup>
      </>
    )
  }

  const getFeedbackMessage = () => {
    const color = loginAjaxResponse.httpCode > 299 ? 'danger' : 'success'

    return onLoading === 'onResult' && <CAlert color={color}>{loginAjaxResponse.message}</CAlert>
  }

  const getActionButtons = () => {
    return (
      <>
        <CRow>
          {onLoading === 'onLoading' ? (
            <CCol xs={12}>
              <div
                style={{
                  height: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <BarLoader color="#444" height={15} width={220} />
              </div>
            </CCol>
          ) : (
            <>
              <CCol xs={6}>
                <CButton
                  onClick={() => {
                    handleLoginButton()
                  }}
                  color="primary"
                  className="px-4"
                >
                  Login
                </CButton>
              </CCol>
              <CCol xs={6} className="text-right">
                <CButton color="link" className="px-0">
                  Esqueceu a senha?
                </CButton>
              </CCol>
            </>
          )}
        </CRow>
      </>
    )
  }

  return (
    <div
      className="bg-light min-vh-100 d-flex flex-row align-items-center"
      style={{
        backgroundImage: `url(${background01})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat',
      }}
    >
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Faça login na sua conta administrativa</p>
                    {getLoginInputs()}
                    {getFeedbackMessage()}
                    {getActionButtons()}
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Grupo Visual Admin</h2>
                    <img src={logo} alt="Logo" style={{ width: '100%' }} />
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
