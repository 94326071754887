import React from 'react'
import { useLocation, Link } from 'react-router-dom'

import routes from '../routes'

import { CBreadcrumb } from '@coreui/react'

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname

  const getRouteName = (pathname, routes, curr) => {
    const currentRoute = routes.find((route) => route.path === pathname)
    return currentRoute !== undefined
      ? { needToActive: false, currentRoute: currentRoute.name }
      : { needToActive: true, currentRoute: curr }
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs = []
    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`
      const { needToActive, currentRoute } = getRouteName(currentPathname, routes, curr)
      breadcrumbs.push({
        pathname: currentPathname,
        name: currentRoute,
        active: needToActive ? true : index + 1 === array.length ? true : false,
      })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <CBreadcrumb className="m-0 ms-2">
      <Link className={'breadcrumb-item'} to={{ pathname: '/' }}>
        Home
      </Link>

      {breadcrumbs.map((breadcrumb, index) => {
        if (breadcrumb.active) {
          return (
            <p key={index} className={'breadcrumb-item'}>
              {breadcrumb.name}
            </p>
          )
        } else {
          return (
            <Link key={index} className={'breadcrumb-item'} to={{ pathname: breadcrumb.pathname }}>
              {breadcrumb.name}
            </Link>
          )
        }
      })}
    </CBreadcrumb>
  )
}

export default React.memo(AppBreadcrumb)
