import { combineReducers } from 'redux'
import { userReducer } from './userReducer'
import { sidebarReducer } from './sidebarReducer'
import { ajaxBffReducer } from './ajaxBffReducer'
import { bffReducer } from './bffReducer'
import { formGenericReducer } from './formGenericReducer'
import { alertReducer } from './alertReducer'
import { toastReducer } from './toastReducer'

const AppReducers = combineReducers({
  userReducer,
  sidebarReducer,
  ajaxBffReducer,
  bffReducer,
  formGenericReducer,
  alertReducer,
  toastReducer,
})

export const rootReducer = (state, action) => {
  return AppReducers(state, action)
}
