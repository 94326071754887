import React from 'react'
import ClockLoader from 'react-spinners/ClockLoader'

const ClockSpinner = (props) => {
  const height = props.height ?? '100vh'

  let colorSpinner = '#444'
  return (
    <div
      style={{ height: height, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <ClockLoader color={colorSpinner} size={230} />
    </div>
  )
}

export default ClockSpinner
