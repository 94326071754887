import React from 'react'
import {
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilStorage, cilCloud, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { Link } from 'react-router-dom'
import { useUserHandler } from '../../service/UserHandler'

const AppHeaderDropdown = () => {
  const { userReducer } = useUserHandler()

  const getUserData = () => {
    if (!userReducer?.userData?.slug) return <p>...</p>
    return (
      <>
        <p style={{ marginBottom: 0 }}>{userReducer.userData?.name}</p>
        <p style={{ marginBottom: 0, color: '#aaa', fontSize: 'small' }}>
          {userReducer.userData?.slug}
        </p>
      </>
    )
  }

  return (
    <CDropdown variant="nav-item" popper={true}>
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        {getUserData()}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Opções</CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilUser} className="me-2" />
          Perfil
        </CDropdownItem>
        <CDropdownDivider />

        {/* <div className="dropdown-item"> */}
        <CDropdownItem component={'button'}>
          <Link to="/administradores">
            <CIcon icon={cilUser} className="me-2" />
            Administradores
          </Link>
        </CDropdownItem>
        {/* </div> */}

        <CDropdownItem component={'button'}>
          <Link to="/administradores_tipos">
            <CIcon icon={cilUser} className="me-2" />
            Tipos Administradores
          </Link>
        </CDropdownItem>

        <CDropdownDivider />
        {/*<CDropdownItem href="/parametros">
          <CIcon icon={cilSettings} className="me-2" />
          Parâmetros
        </CDropdownItem>*/}
        <CDropdownItem component={'button'}>
          <Link to="/servidores">
            <CIcon icon={cilCloud} className="me-2" />
            Servidores
          </Link>
        </CDropdownItem>
        <CDropdownItem component={'button'}>
          <Link to="/banco_de_dados">
            <CIcon icon={cilStorage} className="me-2" />
            Banco de Dados
          </Link>
        </CDropdownItem>
        {/*<CDropdownItem href="/suporte">
          <CIcon icon={cilHeadphones} className="me-2" />
          Suporte
        </CDropdownItem>*/}
        <CDropdownDivider />
        <CDropdownItem href="/logout">
          {/* <CIcon icon={cilLockLocked} className="me-2" /> */}
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
