export const SIGNIN = 'SIGNIN'
export const SIGNOUT = 'SIGNOUT'
export const SETUSERDATA = 'SETUSERDATA'

export const signin = (name, slug) => ({
  type: SIGNIN,
  userData: {
    name: name,
    slug: slug,
  },
})

export const signout = () => ({
  type: SIGNOUT,
  userData: {
    name: null,
    slug: null,
  },
})

export const setUserData = (name, slug) => ({
  type: SETUSERDATA,
  userData: {
    name: name,
    slug: slug,
  },
})
